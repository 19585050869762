#tos-document {
  ol {
    counter-reset: item;
    list-style-type: none; /* remove default numbering */
  }

  li {
    display: block;
    margin-inline-start: 1rem;
    padding-bottom: 1rem;
    position: relative;
    padding-right: 2rem; /* Adjust based on the expected width of the number */
  }
  li li {
    /* level 2 */
    padding-right: 2.5rem;
  }

  li li li {
    /* level 3 */
    padding-right: 3rem;
  }

  li:before {
    content: counters(item, '.') '.';
    counter-increment: item;
    position: absolute;
    right: 0;
    top: 0;
  }

  li > p:first-child {
    display: inline;
  }

  p {
    margin-bottom: 1rem !important;
  }
}
