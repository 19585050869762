.step-wizard-item {
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  padding: 0 25px;
}

.step-wizard-item + .step-wizard-item:after {
  content: '';
  position: absolute;
  left: 0;
  top: 19px;
  background: repeating-linear-gradient(to right, #d7d7d7, #d7d7d7 7px, transparent 7px, transparent 15px);
  width: 100%;
  height: 2px;
  z-index: -10;
}

.step-wizard-item[dir='rtl'] + .step-wizard-item:after {
  transform: translateX(50%);
}

.step-wizard-item[dir='ltr'] + .step-wizard-item:after {
  transform: translateX(-50%);
}

.step-wizard-item:not(.current-item) + .step-wizard-item:after {
  background: #6eb244;
}

/* This is for steps after the current step */
.step-wizard-item.current-item + .step-wizard-item:after,
.current-item ~ .step-wizard-item + .step-wizard-item:after {
  background: repeating-linear-gradient(to right, #d7d7d7, #d7d7d7 7px, transparent 7px, transparent 15px);
}

.step-wizard-list {
  color: #333;
  list-style-type: none;
  border-radius: 10px;
  display: flex;
  position: relative;
  z-index: 10;
}

.step-wizard-list .step-wizard-item:first-child {
  padding-inline-start: 0;
}

.progress-count {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: 600;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  color: transparent;
}
.progress-count:after {
  content: '';
  height: 40px;
  width: 40px;
  background: #6eb244;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  z-index: -10;
}
.progress-count:before {
  content: '';
  height: 10px;
  width: 20px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -60%) rotate(-45deg);
  transform-origin: center center;
}
.progress-label {
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
}

.current-item ~ .step-wizard-item .progress-count {
  color: grey;
}

.current-item .progress-count:before,
.current-item ~ .step-wizard-item .progress-count:before {
  display: none;
}
.current-item ~ .step-wizard-item .progress-count:after {
  background: #fff;
  border: 1px solid #b3b3b3;
}
.current-item ~ .step-wizard-item .progress-label {
  opacity: 0.5;
}
.current-item .progress-count:after {
  background: #eeffe3;
  border: 2px solid #6eb244;
}
.current-item .progress-count {
  color: #6eb244;
}

.step-wizard-item:last-child .progress-count:before {
  display: none;
}
