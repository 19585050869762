
.alert-modal-danger {
    border: #dc3545 1px solid;
    box-shadow: 0px 3.45486px 26.7751px rgb(238 186 186 / 30%);
}

.alert-text-danger {
    color: #dc3545;
}

.alert-modal-success {
    border: #6eb244 1px solid;
    box-shadow: 0px 3.45486px 26.7751px rgba(186, 238, 195, 0.3);
}

.alert-text-success {
    color: #6eb244;
}

.alert-modal-danger .modal-content {
    border: #dc3545 1px solid !important;
}
